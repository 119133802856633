import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Box, {BoxTitle, BoxParagraph } from '../elements/box';
import Card, { CardContent, CardImage } from '../elements/card';
import Diapo from '../elements/diapo';

const Therapie_de_couple = () => (
  <Layout>
    <Box background="primary-light">
      <BoxTitle title="La Thérapie de Couple"/>
      <div className="columns">
        <div className="column is-3">
          <Diapo
            backgroundImages={[ 
              "ncouple3",
              "ncouple4",
              "ncouple2",		
            ]}
            alt="therapie_de_couple"
            size="is-4by5"
          />
        </div>
        <div className="column">
          <BoxParagraph>
            <p><b>A qui s'adresse-t-elle ?</b></p>
            <p>A des couples où règne une insatisfaction mutuelle ou à des couples en crise, qui viennent librement consulter ;</p>
            <p>A des couples qui ont envie de comprendre et de faire évoluer leur dynamique de couple ;</p>
            <p>A des couples en famille recomposée et dont les enjeux se complexifient ;</p>
            <p>A des couples qui démarrent leur relation et qui ont envie de poser des fondations solides et durables ;</p>
            <p>A des couples qui n’arrivent pas à se trouver du temps, l’espace de la thérapie permet de réactualiser 
              son engagement et des projets à rêver ensembles ;</p>
            <p>A des couples où s’exercent des maltraitances à enfant, et ici il s’agira d’injonctions de soins judiciaires. </p>        
          </BoxParagraph>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <BoxParagraph>
            <p><b>Par qui est-elle pratiquée ?</b></p>
            <p>Soit par des conseillers conjugaux, qui sont des profanes ayant reçu une formation spécifique, et qui ont pour 
              mission de servir de médiateurs entre les deux membres du couple. Ils font percevoir à chacun d’eux ses prises 
              de position irrationnelles, le plus souvent inconscientes, qui aboutissent à des conflits réels dans la vie 
              quotidienne ;</p>
            <p>Soit par des psychothérapeutes, formés à l’approche systémique. Ils permettent d’obtenir une modification profonde 
              dans le rapport entre les deux membres du couple et de restaurer une communication rompue. Ils vont également 
              prendre en compte les aspects transgénérationnels toujours présents (tendance à projeter les conflits non 
              résolus avec ses propres parents dans son couple actuel). Ce travail se fait en présence des deux partenaires. 
              Le patient est l’entité couple.</p>        
          </BoxParagraph>
        </div>
        <div className="column is-3">
          <Card height="">
            <CardImage src="illustration5" alt="illustration" />
          </Card>
        </div>
      </div>
      <div className="columns">
        <div className="column is-2">
          <Card height="">
            <CardImage src="illustration6" alt="illustration" />
          </Card>
        </div>
        <div className="column">
          <BoxParagraph>
          <p><b>Comment se déroule-t-elle ?</b></p>
          <p>Ce sont des séances d’une heure et demi qui réunissent les deux membres du couple et un thérapeute, et que l’on 
            répète une ou deux fois par mois, quelquefois plus souvent en cas de crise aiguë.</p>
          <p>Une condition absolue : que chacun des membres du couple soit sincèrement résolu à changer, et non à vouloir que seul 
            l’autre change.</p>        
          </BoxParagraph>
        </div>
      </div>
    </Box>
    <Box>
      <BoxTitle title="Quelques questions pouvant aider dans la démarche d’aller consulter…" />
      <BoxParagraph>
        <p><b>Pourquoi une thérapie de couple ?</b></p>
        <p>La thérapie de couple, tout comme la thérapie individuelle, est une démarche répondant au désir de quitter une 
          souffrance. Tout comme une personne en difficulté, en détresse, peut demander de l'aide avec un espoir légitime 
          d'être secourue, un couple peut effectuer une telle démarche. Tel est le sens général d'une thérapie de couple. 
          Et, bien évidemment, le thérapeute de couple n'est ni un avocat ni un magistrat, et donc il ne fait généralement 
          ni d’arbitrage, ni de jugements.</p>
        
        <p><b>Quand faut-il consulter ?</b></p>
        <p>Quand chacun reproche à l'autre d'être la cause de sa souffrance et se vit comme une victime. Dans un couple, on passe 
          par des hauts et des bas. Or il peut arriver que les deux partenaires n'arrivent plus à sortir de ce système de 
          ressentiment et d'accusations réciproques. Chacun ne voit plus que les problèmes que l'autre lui apporte, ne croit 
          plus en ses capacités de changer. C'est le blocage. On sent qu'alors il ne s'agit plus d'une crise passagère. La seule 
          façon de s'en sortir est d'introduire un tiers dans la relation, qui va permettre de rétablir la communication.</p>

        <p><b>Comment se déroule une thérapie de couple ?</b></p>
        <p>Les façons de faire et les techniques que j’emploie sont très variées. Je travaille en présence des deux membres du 
          couple à chaque séance. La façon d'organiser la parole dans les séances peut varier. Je cherche à comprendre comment 
          un conflit peut exprimer quelque chose de positif, ou d'utile aux deux. J’essaye de mettre en évidence les compétences 
          et les ressources de chaque partenaire. Au cours des séances, j’essaie de trouver des explications dans le passé conjugal 
          des parents des deux clients pour voir s'ils ne reproduisent pas dans leur vie actuelle des relations difficiles de leur 
          passé.</p>

          <p><b>Combien de séances doit-on suivre ?</b></p>
          <p>Parfois quelques séances suffisent à relancer le couple mais la thérapie peut également se prolonger sur 
            plusieurs mois.</p>

          <p><b>Est-ce que ce genre de thérapie est efficace ?</b></p>
          <p>Elle permet de modifier la perception que vous avez de votre couple et vous apporte un regard nouveau, celui de 
            votre conjoint sur la situation et vos problèmes. Cela vous permet de verbaliser des ressentis à votre psy que vous 
            n'auriez jamais confiés auparavant. Chacun est surpris ensuite de l'impact émotionnel de certaines situations.</p>

          <p><b>Peut-on suivre une thérapie individuelle en même temps qu’une thérapie de couple ?</b></p>
          <p>Tout dépend des problèmes qu'il faut traiter. Il est possible qu’une thérapie individuelle pour l'un ou l'autre des 
            membres du couple complète très bien le travail de la thérapie du couple. Comme il peut être aussi nécessaire de se 
            consacrer à sa thérapie de couple et donc de mettre en stand bye sa thérapie individuelle. Chaque situation est 
            singulière.</p>

          <p><b>Réapprendre à se parler, à s’écouter ?</b></p>
          <p>La thérapie de couple invite, quel que soit le nombre d’années de vie commune, à regarder différemment son 
            partenaire. A s’entendre dire les mots que l’on avait du mal à formuler, à comprendre des attentes intimes, à 
            exprimer des désirs enfouis. On y réapprend à se parler et à s’écouter.</p>

          <p><b>Le paradoxe d’une thérapie réussie ?</b></p>
          <p>Si la thérapie permet au couple de remettre à jour le contrat de vie à deux, il arrive qu’elle en scelle la fin. 
            Le paradoxe de la thérapie de couple réside en cela : elle peut être réussie et se conclure par une séparation. 
            En revanche, elle échouera à coup sûr si l’un des deux partenaires refuse de jouer le jeu de l’écoute : malgré lui, 
            lorsque ses blessures personnelles sont trop douloureuses ; à cause de lui, s’il n’a pas envie de sauver ce qui peut 
            encore l’être. Là réside la limite principale de l’exercice : pour réinventer une façon de vivre son couple, pour 
            rendre du souffle à une histoire qui commence à en manquer, il faut toujours être deux.</p>

          <p><b>Seul ou ensemble ?</b></p>
          <p>Lorsque le couple est déséquilibré, la thérapie individuelle ne résout rien : le conjoint qui consulte n’est pas 
            forcément celui qui souffre le plus. Cela peut même être une fuite, et le thérapeute, un objet de rivalité. Il est 
            donc conseillé de consulter à deux. L’école systémique regarde le couple en référence au milieu familial dans lequel 
            il émerge et se développe. Communication rompue, incompréhension ou disputes : le recours à un spécialiste peut 
            aider un couple à sortir de l’impasse. À condition de convaincre le conjoint de tenter l’aventure.</p>

          <p><b>L'infidélité n'est-elle pas aussi un motif fréquent de consultation ?</b></p>
          <p>Il y a souvent des histoires d'infidélité quand un couple va mal. Mais elles sont plutôt le révélateur de la crise 
            que sa cause. Quand on se sent abandonné, plus désirable, alors on se tourne vers une personne plus attentionnée. 
            Parfois, des couples consultent avec l'intention de se séparer sans agressivité, de réfléchir au moyen de préserver 
            au mieux l'équilibre des enfants.</p>

          <p><b>Pourquoi choisir une thérapie de couple plutôt qu'une sexothérapie ou une thérapie personnelle ?</b></p>
          <p>Il y a toujours des problèmes sexuels dans les couples qui viennent en thérapie, mais ce n'est pas le motif 
            essentiel. Chacun a conscience que les relations sexuelles sont devenues insatisfaisantes, voire inexistantes. Tous 
            deux sentent bien que c'est la conséquence de la mésentente et non sa cause. D'ailleurs, quand ça commence à aller 
            mieux, le couple retrouve une libido plus harmonieuse. En revanche, s'il s'agit d'un problème purement physique, 
            mieux vaut consulter un sexologue. Une thérapie de couple se justifie si on est dans un système d'accusations 
            réciproques ; une thérapie individuelle quand la personne se sent mal, indépendamment de sa relation avec son 
            partenaire.</p>

          <p><b>Quel est l'enjeu ?</b></p>
          <p>Le but d'une thérapie n'est pas de déterminer qui a tort ou raison, mais plutôt de rétablir la communication. Le 
            couple est une entité qui a ses règles, ses codes, ses habitudes, et il arrive que les deux partenaires ne s'y 
            reconnaissent plus. Le thérapeute donne les moyens à chacun d'exprimer ses insatisfactions par rapport à la relation 
            et non par rapport à l'autre. Ce changement de perspective permet d'entrer dans un processus de collaboration 
            conjointe pour revoir le « contrat » sans viser à changer l'autre, car c'est impossible. On sort du règlement de 
            comptes destructeur pour devenir constructif.</p>
      </BoxParagraph>
    </Box>
    <div className="columns">
      <div className="column is-half is-offset-one-quarter">
        <Link to='/la_systemie'>
          <Card>
            <CardContent>
              <BoxParagraph>
                <p>Pour en découvrir plus sur la <b>Systémie</b>.</p>
              </BoxParagraph>
            </CardContent>
            <CardImage src="systemie" alt="La Systémie" hoverable="true"/>
          </Card>
        </Link>
      </div>    
    </div>
  </Layout>
)

export default Therapie_de_couple;
